.pac-container {
  padding: 0px;
  background-color: #f7f7f7;
  margin-top: 10px;
}
.pac-item {
  padding: 10px;
}
.pac-item:hover {
  background-color: #FFFFFF;
}
.pac-target-input {
  width: 100%;
  padding: 10px;
  background-color: #FFFFFF;
  border: var(--gt-input-border);
}
