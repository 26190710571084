@import url("./fonts/stylesheet.css");

:root {

  --gte-primary-color: #0d43aa;
  --gte-primary-color-dark: #05286b;
  --gte-white-color: #FFFFFF;
  --gte-neutral-50-color: ##F3F4F7;
  --gte-primary-color-600: #002DAF;
  --gte-primary-color-700: #00207D;
  --gte-primary-color-800: #001757;
  --gte-primary-color-900: #000D32;

  --generic-border-color: #2527341a;
  --generic-border-radius: 5px;

  --font-size-primary: 14px;
  --font-size-sub-mid-primary: 13px;
  --font-size-sub-primary: 12px;
  --font-size-title: 16px;
  --font-size-subtitle: 14px;

  --font-weight-bold: 500;


  --bg-engage: #f9f9f9;
  --bg-engage-dark: rgba(163, 157, 153, 0.38);


  --primary-text-color: #1f0f04;
  --primary-text-color-light: #675c55;

  --generic-error-color: #f25043;

  --message-received-bg-color: #f4f3f3;
  --message-sent-bg-color: #fcefe6;



  --gte-primary-color: #0d43aa;
  --gte-white-color: #FFFFFF;
  --gte-neutral-50-color: ##F3F4F7;
  --gte-primary-600-color: #002DAF;
  --gte-primary-700-color: #00207D;
  --gte-primary-800-color: #001757;

  --tb-bg-hover-color: #00207D;
  --tb-bg-hover-color-dark: #001757;

  --sidebar-main-menu-bg: #FFFFFF;
  --sidebar-main-menu-active-bg: #0d43aa14;
  --sidebar-text-color: #647091;
  --sidebar-text-color-active: #0d43aa;

  --gt-input-border: solid 1px rgba(37, 39, 52, 0.1);
  --generic-border-color: #2527341a;
  --generic-border-radius: 5px;
  --generic-hover-bg-color: #2527341a;
  --generic-card-shadow: 0 3px 20px 0 rgba(0, 0, 0, 0.08);
  --generic-help-msg-color: #7C7D85;
  --generic-sep-color: #E9E9EA;

  --generic-bg-color: #fafafa;
  --tb-bg-hover-color: #08368d;

  --generic-error-color: #f25043;
  --generic-placeholder-color: #838e9f;

  --font-size-primary: 14px;
  --font-size-sub-mid-primary: 13px;
  --font-size-sub-primary: 12px;
  --font-size-input-primary: 16px;
  --font-size-title: 16px;
  --font-size-subtitle: 14px;
  --font-size-top-bar: 16px;
  --font-weight-bold: 500;
  --font-weight-semi-bold: 700;
  --font-weight-extra-bold: 800;


  --btn-color: #0a5fc4;
  --btn-focus-color: #0a5fff;
  --btn-cancel-focus-color: #EEEEEE;
  --btn-disabled-color: #d3d4d6;
  --btn-padding: 8px 32px 8px 32px;

  --login-form-bg-color: #eff3f6;

  --gt-form-line-sep-color: #E9E9EA;
  --gt-form-input-readonly: #f5f0f0;
  --form-help-color: #777575;
  --form-error-color: #f25043;
  --form-success-color: green;



  --resize-icon-color: #c2c1c1;

  --alert-note-bg-color: #fef4e8;
  --alert-note-text-color: #b76c13;

  --gt-form-input-border-radius: 3px;
}

body {
  margin: 0;
  f1ont-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  font-family: 'Montserrat', sans-serif!important;
  font-family: 'Open Sans', sans-serif!important;
  font-family: 'SF Pro Text', sans-serif!important;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: var(--font-size-primary);
  color: var(--primary-text-color);
}
input, select { font-size: 100%; }

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/*
  Use a more-intuitive box-sizing model.
*/
*, *::before, *::after {
  box-sizing: border-box;
}
/*
  Remove default margin
*/
* {
  margin: 0;
  border: 0 none;
}
/*
  Allow percentage-based heights in the application
*/
html, body {
  height: auto!important;
  margin: 0px;
}
/*
  Typographic tweaks!
  Add accessible line-height
  Improve text rendering
*/
body {
  line-height: 1.5!important;
  -webkit-font-smoothing: antialiased;
}
/*
  Improve media defaults
*/
/*img, picture, video, canvas, svg {
  display: block;
  max-width: 100%;
}*/
/*
  Remove built-in form typography styles
*/
input, button, textarea, select {
  font: inherit;
}
/*
  Avoid text overflows
*/
p, h1, h2, h3, h4, h5, h6 {
  overflow-wrap: break-word;
}
/*
  Create a root stacking context
*/
#root, #__next {
  isolation: isolate;
}


input:focus-visible,
textarea:focus-visible {
  outline: none;
}



/* Others */

.gPnlLdng {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  z-index: 1;
  background-color: #ffffff85;
}
.gPnlLdng.wPos {
  top: 0;
  left: 0;
}
.gPnlLdng > span > .fa-spin {
  font-size: 18px;
}



body::-webkit-scrollbar-track
{
	/*-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);*/
	border-radius: 10px;
	background-color: #ffffff;
}
body::-webkit-scrollbar
{
	width: 5px;
	background-color: #ffffff;
}
body::-webkit-scrollbar-thumb
{
	border-radius: 10px;
	/*-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);*/
	background-color: #BDBEC1;
}


/******************** tooltip **************/

.sbTooltip.hide {
  display: none;
}

.sbTooltip.maxWidth {
  max-width: 300px;
}

.infoTooltip.rc-tooltip {
  max-width: 300px;
  opacity: 1;
}
.infoTooltip > .rc-tooltip-content > .rc-tooltip-inner {
  background-color: #FFFFFF;
  color: var(--primary-text-color);
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.5);
  padding: 20px;
}
.infoTooltip > .rc-tooltip-content > .rc-tooltip-arrow {
  1border-right-color: #FFFFFF;
}

.addTooltip > .rc-tooltip-content > .rc-tooltip-inner {
  min-height: 21px;
}

.mtTooltip.rc-tooltip {
  max-width: 400px;
}
/* ****************************** */

.gLbl {
  color: var(--primary-text-color);
  margin-bottom: 10px;
  font-weight: 400;
  font-size: 14px;
  font-weight: var(--font-weight-bold);
  font-stretch: normal;
  font-style: normal;
  line-height: 1.57;
  letter-spacing: normal;
}
.gLbl > span.tltp {
  margin-left: 5px;
  color: grey;
}
.gLbl > span.optional {
  color: var(--primary-text-color-light);
}
.gSLbl {
  color: var(--primary-text-color-light);
  margin-bottom: 5px;
  margin-top: -5px;
  font-weight: 400;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.57;
  letter-spacing: normal;
}

.gIpt input,
.gIpt textarea  {
  width: 100%;
  color: var(--primary-text-color);
  border: var(--gt-input-border);
  border-radius: var(--gt-form-input-border-radius);
  padding: 9px 12px 8px 12px;
  margin-bottom: 1px;
  color: var(--primary-text-color);
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.37;
  letter-spacing: normal;
  resize: vertical;
}
.gIpt.error,
.gIpt.error input, .error > .gIpt.error textarea,
.gIpt.error textarea,
textarea.gInput.error  {
  border-color: var(--generic-error-color);
}
.gIpt > input:read-only,
.gIpt > textarea:read-only {
  background-color: var(--gt-form-input-readonly);
  cursor: default;
}

.gHelpMsg {
  color: var(--generic-help-msg-color);
}

.gLnBkFl {
  width: 100%;
  height: 1px;
  background-color: var(--generic-sep-color);
}

.gDbg {
  margin-bottom: 20px;
  font-size: 12px;
}

.gCrd {
  padding: 20px;
  border-radius: var(--generic-border-radius);
  box-shadow: var(--generic-card-shadow);
}
@media (max-width: 1024px) {
  .gCrd {
    padding: 15px;
  }
}

button.gBtn {
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: var(--gte-primary-color);
  color: var(--gte-white-color);
  border-radius: var(--generic-border-radius);
  font-size: var(--font-size-sub-mid-primary);
  padding: var(--btn-padding);
  cursor: default;
}
button.gBtn:hover {
  opacity: 0.9;
}
button.gBtn.disabled {
  background-color: var(--btn-disabled-color);
  color: var(--primary-text-color);
}
button.gBtn.disabled:hover {
  opacity: 1;
}
button.gBtn > i.fa-spin {
  margin-left: 10px;
}
button.gBtn.cancel {
  background-color: #FFFFFF;
  border: 1px solid var(--primary-text-color);
  color: var(--primary-text-color);
}

/********************** global page wrap **************************/
.gPScrlWrp {
  flex: 1;
  overflow-y: scroll;
  position: relative;
}
.gPScrlWrp.flHt {
  height: 100%;
}
.gPScrlWrp::-webkit-scrollbar-track
{
	/*-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);*/
	border-radius: 10px;
	background-color: #ffffff;
}
.gPScrlWrp::-webkit-scrollbar
{
	width: 5px;
	background-color: #ffffff;
}
.gPScrlWrp::-webkit-scrollbar-thumb
{
	border-radius: 10px;
	/*-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);*/
	background-color: #BDBEC1;
}
.gPg {
  padding: 20px;
}
.gPWrp {
  max-width: 800px;
  padding: 20px;
  margin-bottom: 0px;
  background-color: #FFFFFF;
}
@media (max-width: 1366px) {
  .gPWrp {
    padding: 20px;
    margin: 0 10px;
    margin: 0;
  }
}
.gPWrp > .gPTle {
  font-size: var(--font-size-title);
  padding-bottom: 30px;
  color: var(--primary-text-color);
  font-weight: var(--font-weight-bold);
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.gPWrp > .gPTle.subTitle {
  color: var(--primary-text-color-light);
  font-size: var(--font-size-subtitle);
}
.gPWrp > .gPTle > span.close {
  font-size: 14px;
  font-weight: var(--font-weight-bold);
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  text-align: left;
  color: var(--gte-primary-color);
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: default;
  padding: 4px 6px;
  border-radius: var(--generic-border-radius);
}
.gPWrp > .gPTle > span.close:hover {
  background-color: var(--sidebar-main-menu-active-bg);
}
.gPWrp > .gPTle > span.close > img {
  margin-right: 5px;
  height: 14px;
}
.gPWrp > .gPTle.add {
  justify-content: flex-start;
  gap: 10px;
}
.gPWrp > .gPTle.add > span.add {
  padding: 4px 6px;
  border-radius: var(--generic-border-radius);
}
.gPWrp > .gPTle.add > span.add:hover {
  background-color: var(--sidebar-main-menu-active-bg);
}
.gPWrp > .gPSubTle {
  font-size: 14px;
  padding-bottom: 30px;
  color: var(--primary-text-color);
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  display: flex;
  flex-direction: row;
}
.gPWrp > .gBCtnt {
  display: flex;
  flex-direction: column;
}
.gPWrp > .gBCtnt > .gBCtRw {
  margin-bottom: 20px;
  display: flex;
  flex-direction: row;
  background-color: #FFFFFF;
  line-height: 1.43;
  font-size: var(--font-size-primary);
  color: var(--primary-text-color);
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
}
.gPWrp > .gBCtnt > .gBCtRw > .gRwBxLt {
  flex: 1;
}
.gPWrp > .gBCtnt > .gBCtRw.btnRw {
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  gap: 10px;
  background-color: inherit;
}
.gPWrp > .gBCtnt > .gBCtRw.curSts {
  font-weight: var(--font-weight-bold);
  margin-top: 20px;
  margin-bottom: 0;
  background-color: inherit;
}
.gPWrp > .gBCtnt > .gBCtRw.curSts > .success {
  color: var(--form-success-color);
}
.gPWrp > .gBCtnt > .gBCtRw.curSts > .error {
  color: var(--form-error-color);
}
.gPWrp > .gBCtnt > .gBCtRw > .gRwBxLt {
}
.gPWrp > .gBCtnt > .gBCtRw > .gRwBxRt {
}
.gPWrp > .gBCtnt > .gBCtRw > .lt,
.gPWrp > .gBCtnt > .gBCtRw > .rt {
  display: flex;
  flex-direction: column;
}
.gPWrp > .gBCtnt > .gBCtRw > .lt > .title,
.gPWrp > .gBCtnt > .gBCtRw > .rt > .title {
  font-weight: var(--font-weight-bold);
  margin-bottom: 5px;
}
.gPWrp > .gBCtnt > .gBCtRw > .lt > .desc {
  color: var(--primary-text-color-light);
}
.gPWrp > .gBCtnt > .gBCtRw > .lt > .title > i {
  margin-left: 5px;
}

.gErr {
  color: var(--form-error-color);
  font-weight: 500;
  font-size: var(--font-size-sub-mid-primary);
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
}

/*********************************************/


/* guide page */

.dgPgSecBx {
  margin-bottom: 10px;
}
.dgPgSecBx.image {
  text-align: center;
}
.dgPgSecBx.image > img {
  max-width: 100%;
}
.dgPgSecBx.text > .inr {
  display: flex;
  flex-direction: column;
}
.dgPgSecBx.text > .inr > h3 {
  margin: 10px 0;
}
.dgPgSecBx.text > .inr > p {
  margin-bottom: 10px;
}





/* ******************** Buttons ********************* */

.byTwoRow.buttonsRow {
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: center;
  margin-top: 40px;
  margin-bottom: 20px;
  padding: 0 0px;
}
.byTwoRow.buttonsRow > button {
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: var(--gte-primary-color);
  color: var(--gte-white-color);
  border-radius: var(--generic-border-radius);
  font-size: var(--font-size-sub-mid-primary);
  padding: var(--btn-padding);
  cursor: pointer;
}
.byTwoRow.buttonsRow > button.add:focus,
.byTwoRow.buttonsRow > button.addAndMessage:focus {
  1background-color: var(--btn-focus-color);
}
.byTwoRow.buttonsRow > button.disabled {
  background-color: var(--gt-form-button-disabled);
  color: var(--primary-text-color);
  cursor: not-allowed;
  pointer-events: none;
}
.byTwoRow.buttonsRow > button > .fa {
  margin-left: 10px;
}
.byTwoRow.buttonsRow > button.reverse {
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: var(--gte-white-color);
  color: var(--gte-primary-color);
  border-width: 1px;
  border-style: solid;
  border-radius: var(--generic-border-radius);
  border-color: var(--gte-primary-color);
  font-size: var(--font-size-sub-mid-primary);
  padding: var(--btn-padding);
  cursor: pointer;
}

/*********************************************/

/********************** Modal **************************/

#modal-root {
  position: relative;
  z-index: 999;
}
.cModal {
  background-color: rgba(0,0,0,0.5);
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.cModal > .content {
  max-width: 800px;
  width: 60%;
  width: 700px;
  max-height: 800px;
  1height: 90%;
  background-color: #FFFFFF;
  border-radius: 5px;
}
.cModal > .content > .close {
  float: right;
  margin-right: -3px;
  margin-top: -3px;
  cursor: pointer;
}
.cModal > .content > .close > .fas {
  font-size: 20px;
}
/* ****************************** */


/********************** Side Modal **************************/

#modal-root {
  position: relative;
  z-index: 999;
}
.sdMdl {
  background-color: rgba(0,0,0,0.5);
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  padding: 0!important;

}
.sdMdl > .ctnt {
  max-width: 900px;
  width: 80%;
  height: 100%;
  background-color: #FFFFFF;
  transition: all 0.5s;
  animation: slideInRight 0.5s;
  overflow-y: scroll;
  padding: 20px;
}
.sdMdl > .ctnt::-webkit-scrollbar-track
{
	/*-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);*/
	border-radius: 10px;
	background-color: #ffffff;
}
.sdMdl > .ctnt::-webkit-scrollbar
{
	width: 5px;
	background-color: #ffffff;
}
.sdMdl > .ctnt::-webkit-scrollbar-thumb
{
	border-radius: 10px;
	/*-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);*/
	background-color: #BDBEC1;
}
.sdMdl > .ctnt > .title {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.sideModal > .ctnt > .title > .close {
  float: right;
  margin-right: -3px;
  margin-top: -3px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.sdMdl > .ctnt > .title > .close > img {
  margin-right: 5px;
}

/* ************** side modal *************** */


/* email template modal */

.cModal.etModal {
  background-color: rgba(0,0,0,0.5);
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.cModal.etModal > .content {
  max-width: 1000px;
  width: 80%;
  max-height: 800px;
  1height: 90%;
  background-color: #FFFFFF;
  border-radius: 5px;
  1overflow-y: scroll;
  1overflow-x: hidden;
  /* display: flex;
  justify-content: center;
  align-items: center; */
}
@media (max-width: 1366px) {
  .cModal.etModal > .content {
    width: 80%;
  }
}
.cModal.etModal > .content > .close {
  float: right;
  margin-right: -3px;
  margin-top: -3px;
  cursor: pointer;
}
.cModal.etModal > .content > .close > .fas {
  font-size: 20px;
}

/* email template modal */


/******************************/


#modal-root-alert {
  position: relative;
  z-index: 99900;
}


/* **************** Snack Bar ********************** */

#snackbar {
    visibility: hidden;
    min-width: 250px;
    1margin-left: -125px;
    background-color: #333;
    color: #fff;
    text-align: left;
    border-radius: 2px;
    padding: 20px 20px 20px 20px;
    position: fixed;
    z-index: 99999;
    1left: 50%;
    1right: 10px;
    left: 30px;
    bottom: 30px;
    font-size: 17px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

#snackbar.show1 {
    visibility: visible;
    -webkit-animation: fadein 0.5s, fadeout 0.5s 2.5s;
    animation: fadein 0.5s, fadeout 0.5s 2.5s;
}
#snackbar.show {
    visibility: visible;
    -webkit-animation: fadein 0.5s;
    animation: fadein 0.5s;
}

@-webkit-keyframes fadein {
    from {bottom: 0; opacity: 0;}
    to {bottom: 30px; opacity: 1;}
}

@keyframes fadein {
    from {bottom: 0; opacity: 0;}
    to {bottom: 30px; opacity: 1;}
}

@-webkit-keyframes fadeout {
    from {bottom: 30px; opacity: 1;}
    to {bottom: 0; opacity: 0;}
}

@keyframes fadeout {
    from {bottom: 30px; opacity: 1;}
    to {bottom: 0; opacity: 0;}
}
.close-snack {
  p1osition: absolute;
  r1ight: 10px;
  t1op: 23px;
  height: 23px;
  padding: 5px;
  border-radius: var(--generic-border-radius);
}
.close-snack:hover {
  background-color: grey;
}

/* ****************** End Snack Bar****************** */

/********************** generic list ******************/

.gLstWrp {
  width: 350px;
  height: 100%;
  border-right: 1px solid var(--generic-border-color);
  display: flex!important;
  flex-direction: column;
  position: relative!important;
  transform: none!important;
}
.gLstWrp > .hdr {
  height: 60px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 10px;
  border-bottom: 1px solid var(--generic-border-color);
}
.gLstWrp > .hdr > .ttl {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
}
.gLstWrp > .hdr > .ttl > img {
  height: 16px;
}
.gLstWrp > .gLstBd {
  display: flex;
  flex-direction: column;
}
.gLstWrp > .gLstBd > .gLstRw {
  padding: 10px;
  cursor: pointer;
}
.gLstWrp > .gLstBd > .gLstRw.active {
  background-color: var(--sidebar-main-menu-active-bg);
}
.gLstWrp > .gLstBd > .gLstRw:hover {
  background-color: var(--sidebar-main-menu-active-bg);
}


/********************** End - generic list ******************/

/* Start - Language Selector */

.gLngSlctr > .search {
  border-bottom: 1px solid var(--generic-border-color);
}
.gLngSlctr > .search > input {
  padding: 10px;
  width: calc(100% - 20px);
  max-height: 40px;
}
.gLngSlctr > .langListWrap {
  max-height: 260px;
  overflow-y: scroll;
}
.gLngSlctr > .langListWrap::-webkit-scrollbar-track
{
	/*-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);*/
	border-radius: 10px;
	background-color: #ffffff;
}
.gLngSlctr > .langListWrap::-webkit-scrollbar
{
	width: 5px;
	background-color: #ffffff;
}
.gLngSlctr > .langListWrap::-webkit-scrollbar-thumb
{
	border-radius: 10px;
	/*-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);*/
	background-color: #BDBEC1;
}
.gLngSlctr > .langListWrap > .recentlyUsedTitle {
  padding: 10px 10px;
  font-size: 12px;
  font-weight: 800;
}
.gLngSlctr > .langListWrap > .allTitle {
  padding: 10px 10px;
  font-size: 12px;
  font-weight: 800;
  border-top: 1px solid var(--generic-border-color);
}
.gLngSlctr > .langListWrap > .recentlyUsedList > p,
.gLngSlctr > .langListWrap > .allList > p {
  font-weight: 300;
  border-bottom: 0px solid var(--generic-border-color)!important;
  margin-bottom: 0;
}
.gLngSlctr > .langSettings {
  padding: 10px;
  border-top: 1px solid var(--generic-border-color);
}

/* End - Language Selector */

/* ********** Generic Toggle ********** */

.gToggleWrap {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.gToggleWrap > span {
  margin-right: 10px;
}

.gToggle {
  margin-top: -19px;
}
.gToggle > input[type=checkbox]{
	height: 0;
	width: 0;
	visibility: hidden;
}

.gToggle > label {
	cursor: default;
	text-indent: -9999px;
	width: 40px;
	height: 20px;
	background: #b7bdc7;
	display: block;
	border-radius: 100px;
	position: relative;
}

.gToggle > label:after {
	content: '';
	position: absolute;
	top: 2px;
	left: 2px;
	width: 16px;
	height: 16px;
	background: #fff;
	border-radius: 90px;
	transition: 0.3s;
}

.gToggle > input:checked + label {
	background: #0d43aa;
}

.gToggle > input:checked + label:after {
	left: calc(100% - 2px);
	transform: translateX(-100%);
}

.gToggle > label:active:after {
	width: 20px;
}

/************ End - Generic Toggle **********************/

/************ **********************/

.gToggleBig {
  margin-top: -15px;
}
.gToggleBig > input[type=checkbox]{
	height: 0;
	width: 0;
	visibility: hidden;
}

.gToggleBig > label {
	cursor: pointer;
	text-indent: -9999px;
	width: 40px;
	height: 20px;
	background: #b7bdc7;
	display: block;
	border-radius: 100px;
	position: relative;
}

.gToggleBig > label:after {
	content: '';
	position: absolute;
	top: 2px;
	left: 2px;
	width: 16px;
	height: 16px;
	background: #fff;
	border-radius: 90px;
	transition: 0.3s;
}

.gToggleBig > input:checked + label {
	background: #0d43aa;
}

.gToggleBig > input:checked + label:after {
	left: calc(100% - 2px);
	transform: translateX(-100%);
}

.gToggleBig > label:active:after {
	width: 20px;
}

/******************** **************/


.highlightClass {
  background-color: #ffd54f;
}



.pb10 {
  padding-bottom: 10px!important;
}
.mb20 {
  margin-bottom: 20px!important;
}
.mt30 {
  margin-top: 30px!important;
}

.bold {
  font-weight: var(--font-weight-bold);
}


/*************** Start - Animations **************************/

.rotate {
  animation: rotation 2s infinite linear;
}
@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}

/*************** End - Animations **************************/

.rotateRight180 {
  -webkit-transform:rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg);
  transition: transform 200ms ease;
  -webkit-transition: transform 200ms ease;
  -moz-transition: transform 200ms ease;
  -ms-transition: transform 200ms ease;
  -o-transition: transform 200ms ease;
}
.rotateLeft180 {
  -webkit-transform:rotate(0deg);
  -moz-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  transition: transform 200ms ease;
  -webkit-transition: transform 200ms ease;
  -moz-transition: transform 200ms ease;
  -ms-transition: transform 200ms ease;
  -o-transition: transform 200ms ease;
}


/* ******** Keyframes ************** */

@-webkit-keyframes slideInLeft {
  from {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
    visibility: visible;
  }

  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes slideInLeft {
  from {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
    visibility: visible;
  }

  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@-webkit-keyframes slideInRight {
  from {
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
    visibility: visible;
  }

  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes slideInRight {
  from {
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
    visibility: visible;
  }

  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}



/*** iPhone and iOS Form Input Zoom Fixes ***/
/* Fix Input Zoom on devices older than iPhone 5: */
@media screen and (device-aspect-ratio: 2/3) {
    select, textarea, input[type="text"], input[type="password"],
    input[type="datetime"], input[type="datetime-local"],
    input[type="date"], input[type="month"], input[type="time"],
    input[type="week"], input[type="number"], input[type="email"],
    input[type="url"]{ font-size: 16px; }
}

/* Fix Input Zoom on iPhone 5, 5C, 5S, iPod Touch 5g */
@media screen and (device-aspect-ratio: 40/71) {
    select, textarea, input[type="text"], input[type="password"],
    input[type="datetime"], input[type="datetime-local"],
    input[type="date"], input[type="month"], input[type="time"],
    input[type="week"], input[type="number"], input[type="email"],
    input[type="url"]{ font-size: 16px; }
}

/* Fix Input Zoom on iPhone 6, iPhone 6s, iPhone 7  */
@media screen and (device-aspect-ratio: 375/667) {
    select, textarea, input[type="text"], input[type="password"],
    input[type="datetime"], input[type="datetime-local"],
    input[type="date"], input[type="month"], input[type="time"],
    input[type="week"], input[type="number"], input[type="email"],
    input[type="tel"], input[type="url"]{ font-size: 16px; }
}

/* Fix Input Zoom on iPhone 6 Plus, iPhone 6s Plus, iPhone 7 Plus, iPhone 8, iPhone X, XS, XS Max  */
@media screen and (device-aspect-ratio: 9/16) {
    select, textarea, input[type="text"], input[type="password"],
    input[type="datetime"], input[type="datetime-local"],
    input[type="date"], input[type="month"], input[type="time"],
    input[type="week"], input[type="number"], input[type="email"],
    input[type="tel"], input[type="url"]{ font-size: 16px; }
}
