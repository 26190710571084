.App > h1 {
  padding: 20px;
}
.bxWrp {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 50px;
  padding: 20px;
  width: 786px;
  margin: 0 auto;
  margin-top: 20px;
}
.bxWrp > .gnrtBx {
  background-color: #FFFFFF;
  width: 50%;
}
@media (max-width: 786px) {
  .bxWrp {
    flex-direction: column;
    width: 100%;
  }
  .bxWrp > .gnrtBx {
    width: 90%;
    margin: 0 auto;
  }
}


.bxWrp > .gnrtBx > .rtngBx {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.bxWrp > .gnrtBx > .rtngBx > .tp {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}
.bxWrp > .gnrtBx > .rtngBx > .tp > .stars {
  display: flex;
  justify-content: center;
  transform: scale(0.5);
  margin-top: -40px;
  margin-bottom: -10px;
  max-width: 300px;
}
@media (max-width: 767px) {
  .bxWrp > .gnrtBx > .rtngBx > .tp > .stars {
    transform: scale(0.6);
    max-width: 300px;
  }
}

.bxWrp > .gnrtBx > .rtngBx > .tp > .stars > .star {
  flex: 25%;
  margin: 50px 0;
  position: relative;
  display: inline-block;
  color: red;
  width: 0px;
  height: 0px;
  border-right: 50px solid transparent;
  border-bottom: 33px solid lightgrey;
  border-left: 50px solid transparent;
  transform: rotate(35deg);
}
.bxWrp > .gnrtBx > .rtngBx > .tp > .stars > .star:before {
  border-bottom: 40px solid lightgrey;
  border-left: 15px solid transparent;
  border-right: 25px solid transparent;
  position: absolute;
  height: 0;
  width: 0;
  top: -25px;
  left: -30px;
  display: block;
  content: '';
  transform: rotate(-32deg);
}
.bxWrp > .gnrtBx > .rtngBx > .tp > .stars > .star:after {
  position: absolute;
  display: block;
  color: red;
  top: 0;
  left: -50px;
  width: 0;
  height: 0;
  border-right: 50px solid transparent;
  border-bottom: 35px solid lightgrey;
  border-left: 50px solid transparent;
  transform: rotate(-70deg);
  content: '';
}
.bxWrp > .gnrtBx > .rtngBx > .tp > .stars > .star.active {
  border-bottom-color: gold;
}
.bxWrp > .gnrtBx > .rtngBx > .tp > .stars > .star.active:before {
  border-bottom-color: gold;
}
.bxWrp > .gnrtBx > .rtngBx > .tp > .stars > .star.active:after {
  border-bottom-color: gold;
}
.bxWrp > .gnrtBx > .rtngBx > .tp > .stars > .star.selected {
  border-bottom-color: gold;
}
.bxWrp > .gnrtBx > .rtngBx > .tp > .stars > .star.selected:before {
  border-bottom-color: gold;
}
.bxWrp > .gnrtBx > .rtngBx > .tp > .stars > .star.selected:after {
  border-bottom-color: gold;
}
.bxWrp > .gnrtBx > .rtngBx > .tp > .stars > .star:hover {
  border-bottom-color: gold;
}
.bxWrp > .gnrtBx > .rtngBx > .tp > .stars > .star:hover::before {
  border-bottom-color: gold;
}
.bxWrp > .gnrtBx > .rtngBx > .tp > .stars > .star:hover::after {
  border-bottom-color: gold;
}

.bxWrp > .gnrtBx > .rtngBx > .gErr {
  margin-bottom: 20px;
  margin-top: -10px;
}

.bxWrp > .gnrtBx > .iptBx {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.bxWrp > .gnrtBx > .iptBx > .tp {
  width: 100%;
}
.bxWrp > .gnrtBx > .iptBx > .tp > textarea {
  width: 100%;
  height: 140px;
  border: var(--gt-input-border);
  padding: 5px;
  resize: vertical;
}
.bxWrp > .gnrtBx > .iptBx > .gErr {
  margin-bottom: 30px;
}
.bxWrp > .gnrtBx > .iptBx.adtnl {
  margin-top: 20px;
}
.bxWrp > .gnrtBx > .iptBx.adtnl > .tp > textarea {
  height: 80px;
}
.bxWrp > .gnrtBx > .iptBx.folUp {
  margin-top: 20px;
}
.bxWrp > .gnrtBx > .iptBx.folUp > .tp > textarea {
  height: 80px;
}

.bxWrp > .gnrtBx > .toneBx {
  display: flex;
  flex-direction: row;
  gap: 10px;
  margin-top: 10px;
}
.bxWrp > .gnrtBx > .toneBx > .tbBtn {
  padding: 6px 8px;
  border-radius: 5px;
}
.bxWrp > .gnrtBx > .toneBx > .tbBtn.active {
  background-color: var(--gte-primary-color-900);
  color: #FFFFFF;
}

.bxWrp > .gnrtBx > .btnBx {
  margin-top: 50px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 10px;
}

.bxWrp > .rspnBx {
  width: 50%;
  padding-top: 0px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
}
@media (max-width: 786px) {
  .bxWrp > .rspnBx {
    width: 90%;
    margin: 0 auto;
  }
}
.bxWrp > .rspnBx > .lbl {
  font-weight: bold;
  margin-bottom: 20px;
}
.bxWrp > .rspnBx > .val {
  text-align: left;
}

.bxWrp > .rspnBx > .cpBx {
  margin-top: 16px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}
.bxWrp > .rspnBx > .cpBx > span {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  cursor: default;
}
.bxWrp > .rspnBx > .cpBx > span > i {
  font-size: 20px;
}

.cnLocWrp {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 20px;
  width: 786px;
  margin: 0 auto;
  margin-top: 50px;
}
.cnLocWrp > .cnLoctBx {
  width: 500px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
@media (max-width: 786px) {
  .cnLocWrp {
    width: 100%;
  }
  .cnLocWrp > .cnLoctBx {
    width: 90%;
    margin: 0 auto;
  }
}
.cnLocWrp > .cnLoctBx > .tp {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  font-size: 20px;
}
.cnLocWrp > .cnLoctBx > .frm {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 30px;
  margin-bottom: 100px;
}
.cnLocWrp > .cnLoctBx > .frm > .iptBx {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 20px;
}
.cnLocWrp > .cnLoctBx > .frm > .iptBx > .tp {
  width: 100%;
}
.cnLocWrp > .cnLoctBx > .frm > .iptBx > .tp > input {
  width: 100%;
  border: var(--gt-input-border);
  padding: 5px;
}
.cnLocWrp > .cnLoctBx > .frm > .iptBx > .gErr {
  margin-bottom: 30px;
}
.cnLocWrp > .cnLoctBx > .frm > .stsBx {
  margin-top: 20px;
}
